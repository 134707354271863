import React, { useEffect, useState } from 'react'
import "./Section1.css"
import { Col, Row } from 'react-bootstrap'
import sec1Main from "../../Assets/Images/sec1.png"
import AOS from 'aos';

const Section1 = ({ tfn, getTfnFormat, getTfn, handleClick }) => {

    const [isDesktop, setIsDesktop] = useState(
        window.innerWidth > 600 ? true : false
    );
    const updateDimensions = () => {
        setIsDesktop(window.innerWidth > 700 ? true : false);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [isDesktop]);
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    return (
        <>
            <div className='sec1_main py-5'>
                <div className='container-fluid px-5'>
                    <Row className='sec1_row'>
                        <Col lg={6} className='sec1_col1'>
                            <div>
                                <p className='sec1_p1 mb-4'>INSTANT SERVICES</p>
                                <h2 className='sec1_h2'>Contact Us for <span className='sp_color'>Reliable & Secure</span> <span className='sp_color'><br />Services <br /></span>
                                from Your <span className='sp_color'>Trusted Providers</span></h2>
                                <p style={{ color: "black" }}>
                                Availing services is now easier than ever. Additionally, rapid service program lets you skip the sign-in process, clear your final dues after terminating service, or clear dues on behalf of another person.
                                </p>
                                <a onClick={handleClick} href={tfn === null ? "tel:+18335256234" : getTfn(tfn)} className='sec1_btn'>Contact Us</a>
                            </div>
                        </Col>
                        <Col lg={6} className='sec1_col2'>
                            <img src={sec1Main} alt="sec1Main" className='sec1_img'
                                data-aos={isDesktop ? "slide-left" : ""} />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default Section1