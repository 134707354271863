import React, { useState, useEffect } from "react";
import Section1 from '../../Components/Section1/Section1';
import Section2 from '../../Components/Section2/Section2';
import Component1 from '../../Components/ReusableComponent/Component1/Component1';
import Component2 from '../../Components/ReusableComponent/Component2/Component2';
import img1 from "../../Assets/Images/img1.png";
import img2 from "../../Assets/Images/img2.png";
import img3 from "../../Assets/Images/img3.png";
import img4 from "../../Assets/Images/img4.jpg";
import img5 from "../../Assets/Images/img5.png";
import img6 from "../../Assets/Images/img6.png";
import img7 from "../../Assets/Images/img10.png";
import img8 from "../../Assets/Images/img8.jpg";
import MarquueSection from "../../Components/Marquee/index";
const array1 = [
    "Log in to the My Spectrum app with your Spectrum login information. Learn how to make a username if you don't already have one.",
    "Pick the tab for billing.",
    "Select Make Payment from the menu.",
    "The Payment Amount, Payment Date, and Payment Method can all be changed from this point.",
    "After making sure all of your payment information is accurate, select Make Payment. A message of confirmation will be sent to you."
]
const array2 = [
    "Internet Services",
    "Mobile Services",
    "Wireless Services",
    "TV Services",
"Phone Services",
"Bundled Services"
  
]
const array3 = [
    "Third-party payment centers are for payments only",
    "No third-party payment agent can make a change to a customer's services, resolve an issue, exchange equipment or accept equipment for return",
    "Third-party payment agents are for walk-in payments only. Customers can't mail payments to these locations"
]

const Home = (props) => {
    const [tfn, setTfn] = useState(null);
    const handleClick = () => {
      // Check if the gtag function is defined
    //   if (typeof window.gtag === 'function') {
    //     const phoneNumber = this.state.tfn || "1-833-525-6234";
  
    //     window.gtag('event', 'conversion', {
    //       'send_to': 'AW-606595014/9F__CNHTn94DEMbPn6EC',
  
    //       //  'event_callback': 'callback',
    //       'phone_number': phoneNumber,
    //     });
  
    //   } else {
    //     console.error('Google Analytics gtag function is not defined.');
    //   }
  
    };
  
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      let tfnFromUrl = urlParams.get("tfn");
  
      if (tfnFromUrl) {
        setTfn(tfnFromUrl);
      }
    }, []);
  
    const setUrl = (url) => {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set("tfn", url);
      let newurl =
        window.location.protocol +
        window.location.host +
        window.location.pathname +
        "?" +
        searchParams.toString();
      window.history.pushState({ path: newurl }, "", newurl);
      window.scrollTo(0, 0);
      setTfn(url);
    };
  
    const getTfn = (tfn) => {
      let temp = "";
      for (let i = 0; i < tfn.length; i++) {
        if (tfn[i] !== "-") {
          temp = temp + tfn[i];
        }
      }
      return "tel:+" + temp;
    };
  
    const getTfnFormat = (tfn) => {
      let _tfn = "";
      tfn = tfn.split("-");
      _tfn = "1" + "-" + tfn[1] + "-" + tfn[2] + "-" + tfn[3];
      return _tfn;
    };
    return (
        <>
            <Section1 tfn={tfn} getTfn={getTfn} getTfnFormat={getTfnFormat} handleClick={handleClick}/>
            <MarquueSection/>
            <Section2 tfn={tfn} getTfn={getTfn} getTfnFormat={getTfnFormat} handleClick={handleClick} />
            <Component1 heading1="GET IN TOUCH WITH " heading2="US" img={img1} tfn={tfn} getTfn={getTfn} getTfnFormat={getTfnFormat} handleClick={handleClick}
                para="We provide exceptional services when it comes to using online services securely, we are partnered with many provides to make you able to straighten out your telecom subscriptions with only one call without waiting in queue for hours. We have been providing this service for many years and have brought convenience to many customers of our partners nationwide."
            />
            <Component2 heading1="CONTACT US TODAY AND SAVE BIG"  img={img2} tfn={tfn} getTfn={getTfn} getTfnFormat={getTfnFormat} handleClick={handleClick}
                para="If you’re looking for online secure services support for a residential or small business account, please call us and click on the appropriate button to speak with our specialists."
            />
            <Component1 heading1="ADDITIONAL METHODS SAVE 50% OR MORE"  img={img3} tfn={tfn} getTfn={getTfn} getTfnFormat={getTfnFormat} handleClick={handleClick}
                para="Contact us at our 24/7 support number and we’ll be happy to chalk out a suitable option for you that fits your budgetary needs and challenges along with saving you big bucks."
            />
            <Component2 heading1="YOUR ACCOUNT ONLINE INTERNET AND MOBILE SERVICES AND MORE"  imgS={img4} tfn={tfn} getTfn={getTfn} getTfnFormat={getTfnFormat} handleClick={handleClick}
                para="Our services allow you to do a lot of things, view your history, automate services and go paperless and much more. Every single one is always online."
                // para2="You can check out My Account online, download the app, or use your voice remote to say “My Account” or “Pay my bill.”"
            />
            {/* <Component1 heading1="USE MY INTERNET APP TO " heading2="PAY" img={img5} list={array1} tfn={tfn} getTfn={getTfn} getTfnFormat={getTfnFormat} handleClick={handleClick}
                para="The My Spectrum app on your smartphone lets you pay your bill as well."
            /> */}
            <Component1 heading1="CONTACT US BY PHONE TODAY" img={img6} list={array2} tfn={tfn} getTfn={getTfn} getTfnFormat={getTfnFormat} handleClick={handleClick}
                para="Call us today and avail the best discounts on your services while automating everything and get important upgrades to experience better and more efficient, secure and seamless services."
             
                para3="Simply contact us today and we’ll be able to you out with finding the best fix for your pocket and telecom needs all while saving you big bucks!"
            />
            <Component2 heading1="METHODOLOGY" img={img7} tfn={tfn} getTfn={getTfn} getTfnFormat={getTfnFormat} handleClick={handleClick}
                para="This collection of internet provider deals includes long-standing provider perks and current promotions. We scour the internet for updates, and sometimes internet provider reaches out to us about hot deals."
                
            />
            {/* <Component1 heading1="THIRD-PARTY PAYMENT AGENTS" imgS={img8} imgCurve="right" list={array3} tfn={tfn} getTfn={getTfn} getTfnFormat={getTfnFormat} handleClick={handleClick}
                para="COX payments may also be accepted by additional companies in your town, including Schnucks Supermarkets, Walmart, and Western Union. Although each third-party payment center has its own unique procedures regarding payments, charges, etc., they all respect the following guidelines:"
            /> */}
        </>
    )
}

export default Home