import React, { useEffect, useState } from 'react'
import "./Component1.css";
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import AOS from 'aos';

const Component1 = ({ tfn, getTfnFormat, getTfn, handleClick, img, heading1, heading2, para, list, para3 }) => {
    
    const [isDesktop, setIsDesktop] = useState(
        window.innerWidth > 600 ? true : false
    );
    const updateDimensions = () => {
        setIsDesktop(window.innerWidth > 700 ? true : false);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [isDesktop]);
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    
    return (
        <div className='container-fluid mt-5' style={{ backgroundColor: "#EDF9F9" }}>
            <div className='container py-5 px-3'>
                <Row className='com1'>
                    <Col lg={6}>
                        <div className='div_cen'>
                            <img src={img} className='reComp_img'  data-aos={isDesktop ? "slide-right" : ""} />
                        </div>
                    </Col>
                    <Col lg={6} className='reCom_Col2Main'>
                        <div>
                            <div className='reComp_Col2Div'>
                                <h1 className='reC_Col2h1 py-2'>{heading1}<span>{heading2}</span></h1>
                            </div>
                            <div className='divider_main'>
                                <div className='divider'></div>
                            </div>
                            <p className='reComp_Col2p pt-4'>{para}</p>
                            {list &&
                                <div className='pt-3'>
                                    <ul className='reComp_ul'>
                                        {list.map((data, index) => (
                                            <li key={index} className='reComp_li'>
                                                <FontAwesomeIcon icon={faCheck} size='1x' color='#00a9a4' />
                                                <span className='reComp_text'>{data}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            }
                            {para3 && <p className='py-2'>{para3}</p>}
                            <a onClick={handleClick} href={tfn === null ? "tel:+18335256234" : getTfn(tfn)} className='sec1_btn mt-4'>Contact Us</a>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Component1;
