import Home from "../Containers/Home/Home"
import PrivacyPolicy from "../Containers/PrivacyPolicy/PrivacyPolicy"
import TermsCondition from "../Containers/TermsCondition/TermsCondition"

export const customRoutes = [
    {
        path: '/',
        containers: Home
    },
    {
        path: '/privacy-policy',
        containers: PrivacyPolicy
    },
    {
        path: '/terms-conditions',
        containers: TermsCondition
    }
]