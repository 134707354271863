import React from 'react'
import "./Section2.css"
import { Col, Row } from 'react-bootstrap'
import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeadset, faLock } from '@fortawesome/free-solid-svg-icons';
const Section2 = () => {
    return (
        <>
            <div className='container sec2_main mt-4'>
                <div>
                    <h1 className='sec2_h1'>why choose us?</h1>
                </div>
                <Row className='mt-4'>
                    <Col xs={12} md={4} lg={4} className='my-3'>
                        <div className='sec2_col px-4 pt-3'>
                            <FontAwesomeIcon icon={faChartBar} size='3x' color='#00a9a4' />
                            <h2 className='sec2_h2 my-4'>Low Costing</h2>
                            <p className='sec2_pTag'>You get budget-friendly solutions, helping you save on expenses.</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4} lg={4} className='my-3'>
                        <div className='sec2_col px-4 pt-3'>
                            <FontAwesomeIcon icon={faLock} size='3x' color='#00a9a4' />
                            <h2 className='sec2_h2 my-4'>Safe & Secure</h2>
                            <p className='sec2_pTag'>Your data is protected.</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4} lg={4} className='my-3'>
                        <div className='sec2_col px-4 pt-3'>
                            <FontAwesomeIcon icon={faHeadset} size='3x' color='#00a9a4' />
                            <h2 className='sec2_h2 my-4'>Live Support</h2>
                            <p className='sec2_pTag'>Get real-time assistance whenever you need <br />it.</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Section2